/**
* @tokens Colors
* @presenter Color
*/

/* raw colors */
$om-sage: #007e78;
$om-sage-dark: #004c48;
$om-sage-mid: #66b2ae;
$om-sage-light: #e5f2f1;

$om-jade: #005450;
$om-jade-dark: #004340;
$om-jade-mid: #40887e;
$om-jade-light: #e5eeed;

$light0: #ffffff;
$light1: #f5f5f5;
$light2: #cccccc;

$dark0: #1a1a1a;
$dark1: #666666;
$dark2: #a6a6a6;

$om-sapphire: #2b65ba;
$om-sapphire-dark: #183667;
$om-sapphire-mid: #749bd8;
$om-sapphire-light: #eef3f9;

$om-avocado: #a9cb74;
$om-avocado-dark: #0d6804;
$om-avocado-mid: #c6e09c;
$om-avocado-light: #f0f6e6;

$om-melon: #ffc776;
$om-melon-dark: #cc9f5e;
$om-melon-mid: #ffddad;
$om-melon-light: #ffe9c8;

$om-cherry: #bb2e3c;
$om-cherry-dark: #982430;
$om-cherry-mid: #ff6160;
$om-cherry-light: #faeeef;

/* expanded accent colors */
$om-grapefruit: #e66b60;
$om-grapefruit-dark: #cc4b44;
$om-grapefruit-mid: #ff8c7d;
$om-grapefruit-light: #fdf3f2;

$om-pumpkin: #e37336;
$om-pumpkin-dark: #c4560e;
$om-pumpkin-mid: #ffa462;
$om-pumpkin-light: #fdf4ef;

$om-agave: #00a28f;
$om-agave-dark: #1c7a64;
$om-agave-mid: #89c6b6;
$om-agave-light: #ebf8f6;

$om-aubergine: #7770c3;
$om-aubergine-dark: #4c4e97;
$om-aubergine-mid: #b7b9ee;
$om-aubergine-light: #f4f4fa;

$om-sky: #39acbc;
$om-sky-dark: #1c565e;
$om-sky-mid: #88cdd7;
$om-sky-light: #d7eef2;

$om-wheat: #be8034;
$om-wheat-dark: #4c3315;
$om-wheat-mid: #d8b385;
$om-wheat-light: #f2e6d6;

$amz-prime-blue: #1d98ff;
/* end raw colors */

/* functional names */
$primary: $om-sage;
$primary-dark: $om-sage-dark; // hover
$primary-mid: $om-sage-mid;
$primary-light: $om-sage-light;

$secondary: $om-jade;
$secondary-dark: $om-jade-dark;
$secondary-mid: $om-jade-mid;
$secondary-light: $om-jade-light;

$text-emphasis-high: $dark0;
$text-emphasis-mid: $dark1;
$text-emphasis-low: $dark2;

$background-primary: $light0;
$background-secondary: $light1;
$background-border: $light2;

$info: $om-sapphire;
$info-dark: $om-sapphire-dark;
$info-mid: $om-sapphire-mid;
$info-light: $om-sapphire-light;

$success: $om-avocado;
$success-dark: $om-avocado-dark;
$success-mid: $om-avocado-mid;
$success-light: $om-avocado-light;

$warning: $om-melon;
$warning-dark: $om-melon-dark;
$warning-mid: $om-melon-mid;
$warning-light: $om-melon-light;

$danger: $om-cherry;
$danger-dark: $om-cherry-dark;
$danger-mid: $om-cherry-mid;
$danger-light: $om-cherry-light;
/* end functional names */

/* legacy colors that haven't been migrated over */
$black: #000;
$brand-bg: $light0;
$brand-gray-dark: #343434;
$brand-gray-light: #e4ded9;
$brand-gray-sand-50: rgba(122, 114, 106, 0.5);
$brand-gray-sand: #7a726a;
$cream: #f7f6f4;
$emphasis-medium: $dark1;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-800: #343a40;
$loading-skeleton: rgba(0, 0, 0, 0.03);
$muted-yellow: $om-melon;
$newlightgreen: #c5e5dd;
$om-aubergine-30: rgba(158, 165, 212, 0.3);
$om-charcoal: $dark0;
$om-dark-jade: #004d49;
$om-emerald-10: #cde6e0;
$om-emerald-80: #044f3d;
$om-emerald: #068466;
$om-gold: $om-wheat;
$om-light-emerald: #eef3e5;
$om-sapphire-20: #e1ebf6;
$primary-20: #9bcec2;
$primary-80: #044f3d;
$secondary-100: #cddef0;
$secondary-20: #e1ebf6;
$white: $light0;
$wild-sand: #f4f4f4;
/* end legacy colors that haven't been migrated over */
