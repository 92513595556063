@import 'src/app/omgui/responsive_fonts';
@import 'src/app/omgui/bootstrap-4/main';

html,
body {
  height: 100%;
}

// We specifically want h1s to be bolder than other headings
// h1 should be bold while h2-h6 are semibold
h1,
.h1 {
  font-weight: $font-weight-bolder;
}

// `hidden` attribute preferred over Bootstrap's `.hidden`
// since it also hides it from assistive devices
[hidden] {
  display: none !important;
}

.dropdown-item.active {
  color: $dropdown-link-hover-color;
  background-color: $dropdown-link-hover-bg;
}

.dropdown-item:active {
  color: $dropdown-link-active-color;
  background-color: $dropdown-link-active-bg;
}

.fw-bold {
  font-weight: $font-weight-bolder;
}

.inline-icon-with-text {
  @include inline-icon-with-text-mixin();
}

.no-transition {
  transition: none;
}

/* Custom coloring utilities that aren't themes */
.text-gray-sand-50 {
  color: $brand-gray-sand-50;
}

.subtext-gray-sand {
  color: $brand-gray-sand;
}

.text-red {
  color: $red;
}

.text-emphasis-medium {
  color: $emphasis-medium;
}

.bg-cream {
  background-color: $cream;
}

/* line break helper for flex layout */
.break {
  flex-basis: 100%;
  height: 0;
}

/* globally set input borders to red if wrapper has an error class */
.has-error input {
  border-color: $danger !important;
}

.lh-175 {
  line-height: 1.75rem;
}

.mobile-h2 {
  font-size: 1.75rem;
}

.direct-signup-subtitle {
  line-height: 1.875rem;
  font-size: 1.25rem;
}

.btn.disabled,
button[disabled] {
  cursor: not-allowed;
}

.btn {
  .btn-check:not(:checked) + &:hover {
    background-color: $primary-light;
  }
}

.location-virtual-offering-modal {
  .modal-content {
    border-radius: 12px !important;
  }
}

.modal .modal-dialog.modal-redesign {
  max-width: 100%;

  // Stick modal to the bottom on mobile screens
  @include media-breakpoint-down(sm) {
    display: block;
    margin: 0;
    position: fixed;
    bottom: 0;
    min-height: auto; // Override .modal-dialog-centered
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    max-width: 34rem;
  }

  .modal-content {
    border-radius: $omgui-card-radius;
    border: 0;
  }

  &.no-sm-rounded-bottom {
    .modal-content {
      @include media-breakpoint-down(sm) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .modal-header {
    .modal-header-image {
      max-height: 10rem;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: $omgui-card-radius;
      border-top-right-radius: $omgui-card-radius;

      @include media-breakpoint-up(sm) {
        max-height: 12rem;
      }
    }

    .modal-close {
      position: absolute;
      top: map-get($spacers, 3);
      right: map-get($spacers, 3);
    }
  }
}
