// https://www.figma.com/file/pGz7KqjdAv2kULFZUiEQt3/Alpha---Member-Design-System?node-id=1143%3A11218

@use "sass:math";

@import 'colors';
@import 'bootstrap5-variable-reverts';
@import '/node_modules/bootstrap/scss/variables';
@import '/node_modules/bootstrap/scss/mixins/gradients';
@import '/node_modules/bootstrap/scss/mixins/box-shadow';
@import '/node_modules/bootstrap/scss/vendor/rfs';
@import 'src/app/omgui/bootstrap-4/mixins/buttons';

$theme-colors: (
  'primary': $primary,
  'primary-dark': $primary-dark,
  'primary-mid': $primary-mid,
  'primary-light': $primary-light,
  'secondary': $secondary,
  'secondary-dark': $secondary-dark,
  'secondary-mid': $secondary-mid,
  'secondary-light': $secondary-light,
  'info': $info,
  'info-dark': $info-dark,
  'info-mid': $info-mid,
  'info-light': $info-light,
  'success': $success,
  'success-dark': $success-dark,
  'success-mid': $success-mid,
  'success-light': $success-light,
  'warning': $warning,
  'warning-dark': $warning-dark,
  'warning-mid': $warning-mid,
  'warning-light': $warning-light,
  'danger': $danger,
  'danger-dark': $danger-dark,
  'danger-mid': $danger-mid,
  'danger-light': $danger-light,
  'dark': $warning,
  'light': $light1,
);

$alert-bg-scale: 0;
$alert-border-scale: 0;
$alert-color-scale: 0; // Sets the font color in alerts to black

$link-hover-color: $primary-dark;
$link-color: $primary;
$link-color-20: $primary-light; // TODO: Unused?

$input-focus-border-color: $primary;
$input-height: 3rem;

.btn-alternate {
  @include button-variant($light0, $primary, $primary-light, $primary, $light0, $primary);
}

.text-link,
.cta-link,
.secondary-cta {
  color: $link-color !important;

  &:hover {
    color: $link-hover-color !important;
  }
}

om-home-banner,
om-details,
om-address-input,
om-vitals-details,
om-my-provider,
om-post,
om-payment-form,
om-select-provider,
om-email-verification,
om-plan-type,
om-show-provider-details-modal,
om-membership-cancel,
om-payment-modal,
om-appointments,
om-appointment-filter-container,
om-appointment-confirmation,
om-work-email-step,
om-personal-details-step,
om-family-type,
om-employee-id-step,
om-account-set-up-step,
om-dob-sex-step,
om-enterprise-terms-of-service,
om-email-verification-step,
om-email-verification-registration,
om-name-input,
om-sex-gender-input,
om-adult-direct-signup-form,
om-verified-account-set-up-step,
om-on-behalf-tos,
om-child-account-details,
om-label-wrapper,
.card-body,
.already-member > span,
.register-activate-membership > span,
.register-enter-adress-error-message,
.register-add-gender-info,
.privacy-policy > .rich-text > ol > li,
.not-member > span,
.register-show-preferred-name,
.legal-document,
.consumer-registration-complete {
  a:not(.btn) {
    color: $primary !important;

    &:hover {
      color: $primary-dark !important;
    }
  }
}

.dialog-link {
  color: $primary !important;

  &:hover {
    color: $primary-dark !important;
  }
}

$navbar-height: 80px;
$footer-height: 183px;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    10: $spacer * 5,
  ),
  $spacers
);
// stylelint-enable

$body-bg: $white !default;
$body-color: $om-charcoal !default;
$link-color: $secondary !default; // TODO delete unused assignment
$caret-width: 0.4em !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$omgui-font-family-serif: 'One GT Super Display', serif !default;
$omgui-font-serif-header-weight: 700 !default;

$font-family-sans-serif: 'Ginto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
/*
font size multiplier to pixel conversion table
$font-size-base * 3; // 48px
$font-size-base * 2.5; // 40px
$font-size-base * 2; // 32px
$font-size-base * 1.75; // 28px
$font-size-base * 1.5; // 24px
$font-size-base * 1.25; // 20px
$font-size-base * 1; // 16px
$font-size-base * 0.875; // 14px
 */
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;

$font-weight-lighter: lighter;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 600;

$font-weight-base: $font-weight-normal;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1;

$h1-font-size-sm: $font-size-base * 2;
$h2-font-size-sm: $font-size-base * 1.75;
$h3-font-size-sm: $font-size-base * 1.5;
$h4-font-size-sm: $font-size-base * 1.25;
$h5-font-size-sm: $font-size-base * 1;
$h6-font-size-sm: $font-size-base * 0.875;

$headings-font-weight: 500; // TODO 500 is the default already
$headings-line-height: 1.2; // TODO 1.2 is the default already

$lead-font-size: $font-size-base * 1.25; // TODO $font-size-base * 1.25 is the default already
$lead-font-weight: 300; // TODO 300 is the default already

$small-font-size: 80%;

$line-height-base: 1.4;

$enable-negative-margins: true;

/* legacy font sizes that haven't been migrated over */
$p-font-size-sm: 0.875rem !default;
$ul-font-size-sm: 0.875rem !default;
/* end legacy font sizes that haven't been migrated over */

@import '/node_modules/bootstrap/scss/vendor/rfs';

body {
  font-family: $font-family-base;
  @include font-size($font-size-base);
  font-weight: $font-weight-base;
  line-height: $line-height-base;
}

b,
strong {
  font-weight: $font-weight-bolder; // Add the correct font weight in Chrome, Edge, and Safari
}

h1,
h2,
.h1,
.h2 {
  font-family: $omgui-font-family-serif;
  font-weight: $omgui-font-serif-header-weight;
}

h1,
.h1 {
  margin-top: map-get($spacers, 4);
  margin-bottom: map-get($spacers, 3);
}

h2,
.h2 {
  color: $secondary;
}

h3,
h4,
.h3,
.h4 {
  margin-bottom: map-get($spacers, 3);
}

h5,
h6,
.h5,
.h6 {
  margin-bottom: map-get($spacers, 2);
}

h6,
.h6 {
  font-size: $font-size-base;
}

.body-small {
  font-size: $font-size-sm;
}

@import 'responsive_fonts';

// Components
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;
$border-color: $gray-300 !default;
$border-width: 1px !default;
$border-radius: 0.25rem !default;
$component-active-color: $white !default;
$component-active-bg: $primary !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-line-height: 1.8 !default;

$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color: rgba($component-active-bg, 0.25) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-line-height-lg: $line-height-lg !default;

$input-btn-border-width: $border-width !default;

$btn-font-weight: $font-weight-bolder;

// Forms

$input-padding-y: 0.7rem !default;
$input-padding-x: $input-btn-padding-x !default;
$input-line-height: $input-btn-line-height !default;

$input-color: $body-color !default;
$input-border-color: $gray-400 !default;
$input-border-width: $input-btn-border-width !default;
$input-border-radius: 0 !default;
$input-border-radius-lg: 0 !default;
$input-border-radius-sm: 0 !default;

$input-focus-border-color: lighten($component-active-bg, 25%) !default;

$input-height-border: $input-border-width * 2 !default;
$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$form-select-padding-y: 0.375rem !default;
$form-select-padding-x: 0.75rem !default;
$form-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$form-select-line-height: $input-btn-line-height !default;
$form-select-disabled-color: $gray-600 !default;
$form-select-bg: $white !default;
$form-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$form-select-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$form-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"),
  '#',
  '%23'
) !default;
$form-select-border-width: $input-btn-border-width !default;
$form-select-border-color: $input-border-color !default;
$form-select-border-radius: $border-radius !default;

$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-focus-box-shadow: $input-btn-focus-box-shadow !default;

$form-select-font-size-sm: 75% !default;

$form-select-font-size-lg: 125% !default;

$form-feedback-invalid-color: $danger !default;

// Custom Form Input Variables
// TODO: Remove. These are not bootstrap variables.
$input-invalid-color: $form-feedback-invalid-color;
$input-invalid-box-shadow: rgba($form-feedback-invalid-color, 0.25);

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-link-active-color: $brand-gray-dark !default;
$dropdown-link-active-bg: $brand-gray-light !default;

// TODO: Remove. This is not a bootstrap variable; only used in navbar.
$dropdown-item-height: 52px !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-nav: 100 !default;
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-slide-menu: 1100 !default;

// Navbar

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link

$navbar-light-color: $brand-gray-sand !default;
$navbar-light-hover-color: $brand-gray-dark !default;
$navbar-light-active-color: rgba($black, 0.9) !default;
$navbar-light-disabled-color: rgba($black, 0.3) !default;
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  '#',
  '%23'
) !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;

// Cards

$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: $border-color !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;

// Tooltips

$tooltip-bg: $brand-gray-dark !default;
$tooltip-opacity: 1 !default;

// Badges
// https://www.figma.com/file/to3TUgQD4UwcvQI4Jv7ZCY/Web-Pattern-Library-Guide---Badges?node-id=2202%3A2498

$badge-font-size: $font-size-sm; // Set to 14px, body font size
$badge-padding-y: map-get(
  $map: $spacers,
  $key: 1,
); // 4px each side
$badge-padding-x: map-get(
  $map: $spacers,
  $key: 2,
); // 8px
$badge-border-radius: math.div($border-radius, 2); // 2px

// List group

$list-group-action-color: $body-color !default;
