@import 'src/app/omgui/theme';

@keyframes animation-processing-dash-keyframes {
  0% {
    stroke-dashoffset: 450;
  }
  100% {
    stroke-dashoffset: 150;
  }
}
.animation-processing-dash {
  animation-name: animation-processing-dash-keyframes;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.pre-load {
  display: block !important;
  .preload-container {
    display: table;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;

    .preload-box {
      display: table-cell;
      vertical-align: middle;
      text-align: center;

      .spinner {
        width: 52px;
        height: 52px;

        circle {
          stroke: $primary;
          stroke-width: 3px;
          stroke-linecap: round;
          stroke-dasharray: 150;
          stroke-dashoffset: 40;
          fill: transparent;
        }
      }
    }
  }
}
